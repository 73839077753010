import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import StoreContext, { ScrollContext } from '../../context/Context';

import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useRowSelect,
} from 'react-table';

import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import LoaderSpin from '../../components/LoaderSpin';
import api from '../../services/api';
import './styles.css';
import { Checkbox } from '../../components/TableCheckBox';
import swal from 'sweetalert';

import { useForm } from 'react-hook-form';

import * as S from './products-details.styles';

import { NormalizedData } from '../../utils/NormalizedData';

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={``}
    />
  );
}

const Styles = styled.div`
  display: flex;
  max-width: 100%;
  width: 90%;
  overflow-x: scroll;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    border-collapse: separate !important;
    width: 100%;
    border-spacing: 0;

    thead {
      tr {
        background-color: #eee;
        border-radius: 10px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        th {
          border-width: 0px;
          :first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }
          :last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
          }
        }
      }
    }
    th,
    td {
      width: 0%;

      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const ProductStatus = ['Disponível', 'Atribuído', 'Instalado', 'Indisponível'];

function ProductsDetails() {
  const { userInfoStore, userProjectStore } = useContext(StoreContext);
  const { onScroll } = useContext(ScrollContext);

  const arrowIcon = <SyncAltIcon />;
  const addIcon = <AddIcon />;
  const filterIcon = <FilterListIcon />;

  const [eanCodes, setEanCodes] = useState([]);
  const [attCodes, setAttCodes] = useState(0);

  const [filterOpen, setFilterOpen] = useState(false);

  const [transfer, setTransfer] = useState(false);
  const [transferInd, setTransferInd] = useState(false);
  const [supervisor, setSupervisor] = useState('');
  const [promotor, setPromotor] = useState('');
  const [loadingQuery, setLoadingQuery] = useState(false);

  const [teamsFromSupervisor, setTeamsFromSupervisor] = useState('');
  const [transferRegion, setTransferRegion] = useState('');
  const [regionError, setRegionError] = useState('');
  const [supervisors, setSupervisors] = useState([]);
  const [promotors, setPromotors] = useState([]);
  const [filterPromotors, setFilterPromotors] = useState([]);
  const [filterSupervisors, setFilterSupervisors] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [transferGroups, setTransferGroups] = useState([]);
  const [transferPromotors, setTransferPromotors] = useState([]);
  const [transferSupervisors, setTransferSupervisors] = useState([]);

  const [tagAdded, setTagAdded] = useState(0);
  const [lastAddedTag, setLastAddedTag] = useState({});
  const [tagRemoved, setTagRemoved] = useState(0);
  const [lastRemovedTag, setLastRemovedTag] = useState({});
  const [promotorsError, setPromotorsError] = useState('');
  const [filterGroups, setFilterGroups] = useState('');
  const [filterTeams, setFilterTeams] = useState([]);
  const [oldSelectedFlatRows, setOldSelectedFlatRows] = useState([]);
  const [paramsObj, setParamsObj] = useState('');
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const [tag, setTag] = useState('');

  const location = useLocation();
  const History = useHistory();
  const tagifyRef = useRef();

  const statusOptions = [
    { id: 1, name: 'Disponível' },
    { id: 2, name: 'Atribuído' },
    { id: 3, name: 'Instalado' },
    { id: 4, name: 'Indisponível' },
  ];

  const { register, handleSubmit, watch, setValue } = useForm();

  const watchGroup = watch('id_group_section');
  const watchTeam = watch('id_group');
  const watchFilterSupervisor = watch('id_supervisor');

  const getTeams = useCallback(async () => {
    const response = await api.get(`/groupSections/${watchGroup}`);
    setTeams(response.data.teams || []);
  }, [watchGroup]);

  useEffect(() => {}, [teams]);

  useEffect(() => {
    if (watchGroup) {
      getTeams();
    }
  }, [watchGroup, getTeams]);

  const getPromotors = useCallback(async (regions) => {
    const response = await api.get(
      `/userList?id_users_type=1&id_team=${regions}`
    );
    setFilterPromotors(response.data);
  }, []);

  const getSupervisors = useCallback(async (regions) => {
    const response = await api.get(
      `/userList?id_users_type=2&id_team=${regions}`
    );
    setFilterSupervisors(response.data);
  }, []);

  useEffect(() => {
    if (watchFilterSupervisor) {
      const id = filterSupervisors.filter(
        (supervisor) => supervisor.id === watchFilterSupervisor
      ).id_team;
      setTeamsFromSupervisor(id);
      setValue('id_group', id);
    }
  }, [watchFilterSupervisor, filterSupervisors, setValue]);

  useEffect(() => {
    if (teamsFromSupervisor) {
      getPromotors(teamsFromSupervisor);
    } else if (watchTeam && !teamsFromSupervisor) {
      getPromotors(watchTeam);
    }
  }, [teamsFromSupervisor, watchTeam, getPromotors]);

  useEffect(() => {
    if (watchTeam) {
      getSupervisors(watchTeam);
    }
  }, [watchTeam, getSupervisors]);

  const emptyFields = () => {
    tagifyRef.current.removeAllTags();

    if (promotor) {
      setData(data.filter((elm) => !eanCodes.includes(elm.ean_code)));
    } else {
      filterData({ id_sku_status: transfer ? 1 : 4 }, true);
      getTransferGroups();
      setCol();
    }

    setTransferRegion('');
    setPromotors([]);
    setSupervisor('');
    setPromotor('');
    setTag('');
    setAttCodes(0);
    setEanCodes([]);
    setFilterGroups('');
  };

  const reloadPage = () => {
    const state = location.state;
    const pathname = '/ProductsDetails';

    History.push('/Products');
    History.push({
      pathname: pathname,
      state,
    });
  };

  const tranferSkus = async () => {
    const transferData = {
      id_sku_category: location.state.id,
    };

    transferData.id_project = userProjectStore.id;

    let ean_codes = eanCodes;

    if (
      transferRegion === '' &&
      userInfoStore.id_users_type > 2 &&
      userInfoStore.id_users_type !== 6
    ) {
      swal({
        title: 'Atenção',
        text: 'Grupo e Time devem ser selecionados!',
        icon: 'info',
        button: 'Fechar',
      });

      setRegionError(true);

      return;
    }

    setRegionError(false);

    if (ean_codes.length <= 0) {
      swal({
        title: 'Atenção',
        text: 'Nenhum produto foi adicionado.',
        icon: 'info',
        button: 'Fechar',
      });

      return;
    }

    transferData.ean_codes = ean_codes;

    if (userInfoStore.id_users_type === 2) {
      if (!promotor) {
        swal({
          title: 'Atenção',
          text: 'Nenhum promotor foi selecionado.',
          icon: 'info',
          button: 'Fechar',
        });

        setPromotorsError(true);

        return;
      }

      setPromotorsError(false);

      transferData.id_supervisor = userInfoStore.id;
      transferData.id_user = promotor;
    } else {
      if (transferRegion) transferData.id_region = transferRegion;
      if (supervisor) transferData.id_supervisor = supervisor;
      if (promotor) transferData.id_user = promotor;
    }

    if (tag) {
      transferData.id_tag = tag;
    }

    try {
      const response = await api.post(`/assignment`, transferData);

      if (response.status === 200) {
        swal(
          response?.data?.message ?? `Transferência realizada com sucesso!`,
          {
            icon: 'success',
            button: 'Fechar',
          }
        );
        emptyFields();
        reloadPage();
      }
    } catch (error) {
      const data = error?.response?.data;

      swal({
        title: 'Atenção',
        text: data?.error ?? 'Algo deu errado',
        icon: 'warning',
        button: 'Fechar',
      });
    }

    setCol();
  };

  const setCol = () => {
    setColumns([
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Código',
        accessor: (elm) => (
          <div className="tooltip">
            {elm['ean_code']
              ? elm['ean_code'].length < 10
                ? elm['ean_code']
                : '...' +
                  elm['ean_code'].substring(
                    elm['ean_code'].length - 7,
                    elm['ean_code'].length
                  )
              : 'Sem código'}
            <span className="tooltiptext">
              {elm['ean_code'] || 'Sem código'}
            </span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Grupo',
        accessor: 'group',
      },
      {
        Header: 'Time',
        accessor: 'region',
      },
      {
        Header: 'Supervisor',
        accessor: 'supervisor_username',
      },
      {
        Header: 'Vendedor',
        accessor: 'promotor_username',
      },
      {
        disableFilters: true,
        Header: 'Criação',
        accessor: (elm) =>
          new Date(elm['created_at']).toLocaleString([], {
            dateStyle: 'short',
            timeStyle: 'short',
          }),
      },
      {
        disableFilters: true,
        Header: 'Atualização',
        accessor: (elm) => NormalizedData(elm['updated_at']),
      },
    ]);
  };

  const getTransferGroups = useCallback(async () => {
    const response = await api.get(
      `/groupUser?id_project=${userProjectStore.id}`
    );

    setTransferGroups(response.data);
  }, [userProjectStore.id]);

  const getData = useCallback(async () => {
    if (!render) {
      setLoading(true);

      const supervisors = (
        await api.get(
          `/userList?id_users_type=2&id_project=${userProjectStore.id}`
        )
      ).data;
      const promotores = (
        await api.get(`/promotors?id_project=` + userProjectStore.id)
      ).data;
      const groups = await api.get(
        '/groupSections?id_project=' + userProjectStore.id
      );
      const firstTeams = await api.get(
        `/groups?id_project=${userProjectStore.id}`
      );

      setGroups(groups.data);
      setPromotors(promotores);
      setSupervisors(supervisors);
      setFilterSupervisors(supervisors);
      setFilterPromotors(promotores);
      setTeams(firstTeams.data);

      const resp = (
        await api.get(
          `/products/list?id_project=${userProjectStore.id}&id_category=${location.state.id}&page=1`
        )
      ).data;
      const { data: response } = resp;

      setCol();

      setLastPage(resp.lastPage);

      setData(response || []);

      setLoading(false);
    }
  }, [location.state.id, userProjectStore.id, render]);

  const getInitialPromotors = async () => {
    const response = await api.get(
      `/promotors?id_project=${userProjectStore.id}`
    );

    setTransferPromotors(response.data);
  };

  useEffect(() => {
    if (transfer || transferInd) {
      filterData({ id_sku_status: transfer ? 1 : 4 }, true);
      getInitialPromotors();
      getTransferGroups();
      setCol();
    } else {
      filterData({});
      setCol();
    }
  }, [transfer, transferInd]);

  const getSupervisorAndPromotor = useCallback(async (region) => {
    const transferPromotors = await api.get(
      `/userList?id_users_type=1&id_team=${region}`
    );
    const transferSupervisors = await api.get(
      `/userList?id_users_type=2&id_team=${region}`
    );
    setTransferPromotors(transferPromotors.data);
    setTransferSupervisors(transferSupervisors.data);
  }, []);

  useEffect(() => {
    if (transferRegion) {
      getSupervisorAndPromotor(transferRegion);
    }
  }, [getSupervisorAndPromotor, transferRegion]);

  const fetchOnQuery = async () => {
    if (page < lastPage) {
      setLoadingQuery(true);

      let paramString = '';

      Object.entries(paramsObj).forEach(([key, value]) => {
        if (value && key) {
          paramString += `&${key}=${value}`;
        }
      });

      let url = `/products/list?id_project=${userProjectStore.id}&id_category=${
        location.state.id
      }&page=${page + 1}`;

      if (paramString) {
        url = url + paramString;
      }

      const resp = (await api.get(url)).data;

      setPage(page + 1);

      const { data: response } = resp;

      setLastPage(resp.lastPage);

      const newArray = [...data, ...response];

      setData(newArray);

      setLoadingQuery(false);
    }
  };

  useEffect(() => {
    if (
      onScroll?.target.scrollTop /
        (onScroll?.target.scrollHeight - onScroll?.target.clientHeight) >
      0.8
    ) {
      if (!loadingQuery) {
        fetchOnQuery();
      }
    }
  }, [fetchOnQuery, loadingQuery, onScroll]);

  const getFilteredData = async (filterString) => {
    let getString = `products/list?id_project=${userProjectStore.id}&id_category=${location.state.id}&page=1`;

    getString += filterString;

    const response = await api.get(getString);

    setLastPage(response.data.lastPage ?? 99);

    setData(response.data.data || []);
  };

  const filterTypes = () => ({
    // Add a new fuzzyTextFilterFn filter type.
    StatusSelect: (rows, id, filterValue) => {
      return rows.filter((row, index) => {
        const rowValue = row.values[id];
        return rowValue !== undefined ? data[index][id] == filterValue : true;
      });
    },
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
          : true;
      });
    },
  });

  useEffect(() => {
    if (render === false) {
      getData();

      setRender(true);
    }
  }, [getData]);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
  } = useTable(
    { columns, defaultColumn, data, filterTypes },
    useFilters,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (transfer || transferInd)
          return [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row }) => (
                <Checkbox {...row.getToggleRowSelectedProps()} />
              ),
            },
            ...columns,
          ];
        else return [...columns];
      });
    }
  );

  const onRemoveSelectedFromTable = (removed) => {
    if (tagifyRef.current?.value) {
      tagifyRef.current.removeTags(removed.map((e) => e));
      setAttCodes(attCodes + 1);
    }
  };

  const onAddSelectedFromTable = (added) => {
    if (tagifyRef.current?.value) {
      setAttCodes(attCodes + 1);
      tagifyRef.current.addTags(added.map((e) => e.original.ean_code));
    }
  };

  useEffect(() => {
    if (tagifyRef?.current?.value) {
      setEanCodes([...tagifyRef?.current?.value.map((e) => e.value)]);
    }
  }, [attCodes]);

  useEffect(() => {
    if (oldSelectedFlatRows.length > selectedFlatRows.length) {
      onRemoveSelectedFromTable(
        oldSelectedFlatRows.filter(
          (row) =>
            !selectedFlatRows.map((e) => e.original.ean_code).includes(row)
        )
      );
      setOldSelectedFlatRows(selectedFlatRows.map((e) => e.original.ean_code));
    } else {
      onAddSelectedFromTable(
        selectedFlatRows.filter(
          (row) => !oldSelectedFlatRows.includes(row.original.ean_code)
        )
      );
      setOldSelectedFlatRows(selectedFlatRows.map((e) => e.original.ean_code));
    }
  }, [selectedFlatRows]);

  const getTransferTeams = async (group) => {
    const response = await api.get(`/groupSections/${group}`);
    setFilterTeams(response?.data?.teams || []);
  };

  useEffect(() => {
    if (filterGroups) {
      getTransferTeams(filterGroups);
    }
  }, [filterGroups]);

  const filterData = (data, maintainOld) => {
    let paramString = '';
    let paramObj;

    if (maintainOld) {
      if (Object.keys(data).length > 0) {
        paramObj = { ...paramsObj, ...data };
      } else {
        paramObj = paramsObj;
      }
    } else {
      paramObj = data;
    }

    Object.entries(paramObj).forEach(([key, value]) => {
      if (value && key) {
        paramString += `&${key}=${value}`;
      }
    });

    setParamsObj(paramObj);

    getFilteredData(paramString);
  };

  const onRemoveTag = (elm) => {
    const newEanCodes = eanCodes.splice(0);
    const removed = newEanCodes.findIndex((e) => elm.detail.data.value === e);
    if (removed >= 0) newEanCodes.slice(removed, 1);
    setEanCodes(newEanCodes);
    setLastRemovedTag(elm.detail.data.value);
    setTagRemoved(tagRemoved + 1);
  };

  const onAddTag = (elm) => {
    setEanCodes((eancode) => [...eancode, elm.detail.data.value]);
    setLastAddedTag(elm.detail.data.value);
    setTagAdded(tagRemoved + 1);
  };

  useEffect(() => {
    const id = data.findIndex((e) => e.ean_code === lastRemovedTag);

    if (id >= 0) toggleRowSelected(id.toString(), false);

    const idSelected = data.findIndex((e) => e.ean_code === lastAddedTag);

    if (idSelected >= 0) toggleRowSelected(idSelected.toString(), true);
  }, [data, lastAddedTag, lastRemovedTag, tagRemoved, toggleRowSelected]);

  const getTags = async () => {
    const response = await api.get(`/skuCategoryTag/${location.state.id}`);

    setTags(response.data);
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <div className="fieldsMainContainer">
      <div className="topCustomFieldsContainer">
        <div className="cardProductss" style={{ marginBottom: 10 }}>
          <h3 className="ProductTitleDts" style={{ paddingRight: 0 }}>
            {location.state.name}
          </h3>
          <S.ButtonContainer>
            <S.Button
              onClick={() => {
                setFilterOpen(!filterOpen);
                if (!filterOpen) {
                  setTransfer(false);
                  setTransferInd(false);
                }
              }}
            >
              {filterIcon} <span>Filtro</span>
            </S.Button>
            {userInfoStore.permissions.includes('products_create') && (
              <S.Button
                onClick={() => {
                  History.push('/ProductsCategory', location.state);
                }}
              >
                {addIcon}
                <span>Novo Produto</span>
              </S.Button>
            )}

            <S.Button
              onClick={() => {
                setTransfer(!transfer);
                setTransferInd(false);
                if (!transfer) {
                  setFilterOpen(false);
                }
              }}
              className="btnProductsDts"
            >
              {arrowIcon} <span>Transferência</span>
            </S.Button>

            {userInfoStore.permissions &&
              userInfoStore.permissions.includes(
                'transferproductsunavailable_read'
              ) && (
                <S.Button
                  style={{ maxWidth: '240px' }}
                  onClick={() => {
                    setTransferInd(!transferInd);
                    setTransfer(false);
                    if (!transferInd) {
                      setFilterOpen(false);
                    }
                  }}
                  className="btnProductsDts"
                >
                  {arrowIcon} <span>Transferência / Indisponíveis</span>
                </S.Button>
              )}

            <S.Button
              onClick={() => History.push('/Products')}
              className="btnProductsDts"
            >
              <span>Voltar</span>
            </S.Button>
          </S.ButtonContainer>
        </div>
      </div>
      {filterOpen && (
        <S.BoxFilter>
          <form onSubmit={handleSubmit(filterData)}>
            <div>
              <S.Input
                {...register('ean_code')}
                placeholder="Código"
                placeholderTextColor="#BBBBBB"
                primary
              />
              <S.Select {...register('id_group_section')}>
                <option value="">Grupos</option>
                {groups?.map((options, index) => (
                  <option value={options.id} key={`${index + options}`}>
                    {options.section}
                  </option>
                ))}
              </S.Select>
              <S.Select disabled={!teams.length} {...register('id_group')}>
                <option value="">Times</option>
                {teams?.map((options, index) => (
                  <option value={options.id} key={`${index + options}`}>
                    {options.name}
                  </option>
                ))}
              </S.Select>
              <S.Select {...register('id_sku_status')}>
                <option value="">Status</option>
                {statusOptions.map((options, index) => (
                  <option value={options.id} key={`${index + options}`}>
                    {options.name}
                  </option>
                ))}
              </S.Select>
            </div>
            <div>
              <S.Select {...register('id_supervisor')} primary>
                <option value="">Supervisor</option>
                {filterSupervisors?.map((options, index) => (
                  <option value={options.id} key={`${index + options}`}>
                    {options.username}
                  </option>
                ))}
              </S.Select>
              <S.Select
                disabled={!filterPromotors.length}
                {...register('id_user')}
                primary
              >
                <option value="">Promotor</option>
                {filterPromotors?.map((options, index) => (
                  <option value={options.id} key={`${index + options}`}>
                    {options.username}
                  </option>
                ))}
              </S.Select>
              <S.Button type="submit">{filterIcon}Filtrar</S.Button>
            </div>
          </form>
        </S.BoxFilter>
      )}

      {transferInd && (
        <S.BoxFilter>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              marginBottom: '20px',
            }}
          >
            <S.FirstOptions>
              <div>
                {userInfoStore.id_users_type === 5 ||
                userInfoStore.id_users_type === 6 ? (
                  <S.SelectHalf
                    primary
                    value={filterGroups}
                    onChange={(elm) => {
                      setFilterGroups(elm.target.value);
                      setTransferRegion('');
                    }}
                    className={`inputRounded ${
                      regionError ? 'invalidInput' : null
                    }`}
                  >
                    <option value="" disabled selected>
                      Grupo
                    </option>
                    {transferGroups?.map((elm) => (
                      <option value={elm.id} key={elm.id + elm.section + 3}>
                        {elm.section}
                      </option>
                    ))}
                  </S.SelectHalf>
                ) : null}
                {userInfoStore.id_users_type === 5 ||
                userInfoStore.id_users_type === 6 ? (
                  <S.SelectHalf
                    primary
                    value={transferRegion}
                    onChange={(elm) => setTransferRegion(elm.target.value)}
                    className={`inputRounded ${
                      regionError ? 'invalidInput' : null
                    }`}
                  >
                    <option value="" disabled selected>
                      Time
                    </option>
                    {filterTeams?.map((elm) => (
                      <option value={elm.id} key={elm.id + elm.section + 4}>
                        {elm.name}
                      </option>
                    ))}
                  </S.SelectHalf>
                ) : null}
              </div>
              {userInfoStore.id_users_type === 6 && (
                <S.SelectTransfer
                  style={{ margin: '12px' }}
                  disabled={tags.length === 0}
                  value={tag}
                  onChange={(elm) => setTag(elm.target.value)}
                >
                  <option value="" selected>
                    Selecione a Tag
                  </option>
                  {tags.map((elm) => (
                    <option value={elm.id} key={elm.id}>
                      {elm.name}
                    </option>
                  ))}
                </S.SelectTransfer>
              )}
            </S.FirstOptions>

            <S.StyledTagDiv>
              <S.StyledTag
                id="products"
                tagifyRef={tagifyRef}
                placeholder="Selecione ou digite o código do produto"
                style={{ width: '100%', maxHeight: '150px' }}
                onAdd={onAddTag}
                onRemove={onRemoveTag}
              />
            </S.StyledTagDiv>
            <S.TransferButtonDiv>
              <S.Button
                onClick={() => {
                  tranferSkus();
                }}
              >
                {arrowIcon}
                <span>Transferir</span>
              </S.Button>
            </S.TransferButtonDiv>
          </div>
          <p
            style={{
              fontSize: 13,
              fontWeight: 'bold',
              color: '#555555',
              alignSelf: 'flex-end',
              marginLeft: '15px',
              whiteSpace: 'noWrap',
            }}
          >
            {eanCodes.length} produtos selecionados
          </p>
        </S.BoxFilter>
      )}
      {transfer && (
        <S.BoxFilter>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              marginBottom: '20px',
            }}
          >
            <S.FirstOptions>
              <div>
                {userInfoStore.id_users_type === 5 ||
                userInfoStore.id_users_type === 6 ? (
                  <S.SelectHalf
                    primary
                    value={filterGroups}
                    onChange={(elm) => {
                      setFilterGroups(elm.target.value);
                      setTransferRegion('');
                    }}
                    className={`inputRounded ${
                      regionError ? 'invalidInput' : null
                    }`}
                  >
                    <option value="" disabled selected>
                      Grupo
                    </option>
                    {transferGroups?.map((elm) => (
                      <option value={elm.id} key={elm.id + elm.section + 3}>
                        {elm.section}
                      </option>
                    ))}
                  </S.SelectHalf>
                ) : null}
                {userInfoStore.id_users_type === 5 ||
                userInfoStore.id_users_type === 6 ? (
                  <S.SelectHalf
                    primary
                    value={transferRegion}
                    onChange={(elm) => setTransferRegion(elm.target.value)}
                    className={`inputRounded ${
                      regionError ? 'invalidInput' : null
                    }`}
                  >
                    <option value="" disabled selected>
                      Time
                    </option>
                    {filterTeams?.map((elm) => (
                      <option value={elm.id} key={elm.id + elm.section + 4}>
                        {elm.name}
                      </option>
                    ))}
                  </S.SelectHalf>
                ) : null}
              </div>

              {userInfoStore.id_users_type === 5 ||
              userInfoStore.id_users_type === 6 ? (
                <S.SelectTransfer
                  value={supervisor}
                  onChange={(elm) => setSupervisor(elm.target.value)}
                >
                  <option value="" selected>
                    Supervisor
                  </option>
                  {transferSupervisors.map((elm) => (
                    <option value={elm.id} key={elm.id + elm.username + 1}>
                      {elm.username}
                    </option>
                  ))}
                </S.SelectTransfer>
              ) : null}
              {userInfoStore.id_users_type === 5 ||
              userInfoStore.id_users_type === 2 ||
              userInfoStore.id_users_type === 6 ? (
                <S.SelectTransfer
                  value={promotor}
                  onChange={(elm) => setPromotor(elm.target.value)}
                  invalidInput={promotorsError}
                >
                  <option value="" selected>
                    Selecione um promotor
                  </option>
                  {transferPromotors
                    .sort(
                      (a, b) =>
                        a.fullname
                          .toLowerCase()
                          .localeCompare(b.fullname.toLowerCase()) -
                        b.fullname
                          .toLowerCase()
                          .localeCompare(a.fullname.toLowerCase())
                    )
                    .map((elm) => (
                      <option value={elm.id} key={elm.id + elm.username + 2}>
                        {elm.fullname}
                      </option>
                    ))}
                </S.SelectTransfer>
              ) : null}

              {userInfoStore.id_users_type === 6 && (
                <S.SelectTransfer
                  disabled={tags.length === 0}
                  value={tag}
                  onChange={(elm) => setTag(elm.target.value)}
                >
                  <option value="" selected>
                    Selecione a Tag
                  </option>
                  {tags.map((elm) => (
                    <option value={elm.id} key={elm.id}>
                      {elm.name}
                    </option>
                  ))}
                </S.SelectTransfer>
              )}
            </S.FirstOptions>

            <S.StyledTagDiv>
              <S.StyledTag
                id="products"
                tagifyRef={tagifyRef}
                placeholder="Selecione ou digite o código do produto"
                style={{ width: '100%', maxHeight: '150px' }}
                onAdd={onAddTag}
                onRemove={onRemoveTag}
              />
            </S.StyledTagDiv>
            <S.TransferButtonDiv>
              <S.Button
                onClick={() => {
                  tranferSkus();
                }}
              >
                {arrowIcon}
                <span>Transferir</span>
              </S.Button>
            </S.TransferButtonDiv>
          </div>
          <p
            style={{
              fontSize: 13,
              fontWeight: 'bold',
              color: '#555555',
              alignSelf: 'flex-end',
              marginLeft: '15px',
              whiteSpace: 'noWrap',
            }}
          >
            {eanCodes.length} produtos selecionados
          </p>
        </S.BoxFilter>
      )}

      {loading ? (
        <LoaderSpin type={'Grid'} enable={loading} />
      ) : (
        <Styles>
          <table {...getTableProps()} className="fieldsTable">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index + 22}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    style={{
                      cursor:
                        userInfoStore.permissions.includes('products_edit') ||
                        userInfoStore.permissions.includes('products_read')
                          ? 'pointer'
                          : '',
                    }}
                    key={row.original.id}
                    className="fieldsTableRow"
                    onClick={() => {
                      if (
                        transfer === false &&
                        transferInd === false &&
                        (userInfoStore.permissions.includes('products_edit') ||
                          userInfoStore.permissions.includes('products_read'))
                      ) {
                        History.push(
                          `/ProductInfo/${row.original.id}`,
                          location.state
                        );
                      }
                    }}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td {...cell.getCellProps()} key={index + 11}>
                          {cell.render('Cell')}
                          {ProductStatus.includes(cell.value) &&
                            cell?.row?.original?.tag?.name && (
                              <S.TagDiv>
                                <S.Tag>
                                  {cell?.row?.original?.tag?.name.length <= 11
                                    ? cell?.row?.original?.tag?.name
                                    : cell?.row?.original?.tag?.name.substring(
                                        0,
                                        9
                                      ) + '...'}
                                </S.Tag>
                              </S.TagDiv>
                            )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Styles>
      )}
    </div>
  );
}

export default ProductsDetails;
